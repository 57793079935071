import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M9 1.5A7.5 7.5 0 0 0 7.83 16.409v-5.241H5.925V9h1.904V7.348c0-1.88 1.12-2.918 2.833-2.918.82 0 1.679.146 1.679.146v1.846h-.946c-.932 0-1.222.578-1.222 1.171V9h2.08l-.333 2.168h-1.747v5.24A7.502 7.502 0 0 0 9 1.5Z"
    />
  </svg>
);
