import * as React from "react";
import type { SVGProps } from "react";

export const SvgGameDeadlock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2 8.00005C15.2 11.9765 11.9764 15.2 7.99999 15.2C4.02354 15.2 0.799988 11.9765 0.799988 8.00005C0.799988 4.0236 4.02354 0.800049 7.99999 0.800049C11.9764 0.800049 15.2 4.0236 15.2 8.00005Z"
      fill="#EFDEC0"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.2 8.11018H0.799988V7.88977H15.2V8.11018Z" fill="#242021" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.11018 0.799952L8.11018 15.2L7.88977 15.2L7.88977 0.799952L8.11018 0.799952Z"
      fill="#242021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72829 3.57247L12.4344 12.2786L12.2786 12.4344L3.57244 3.72833L3.72829 3.57247Z"
      fill="#242021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4344 3.72829L3.7283 12.4344L3.57245 12.2786L12.2786 3.57244L12.4344 3.72829Z"
      fill="#242021"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55511 7.99995L6.14491 6.16322C6.95307 5.68567 8.4938 5.229 9.83675 6.16322C10.3981 6.55371 10.9198 6.90816 11.3555 7.20424C11.9036 7.57665 12.3158 7.8567 12.5 7.99995H3.55511ZM3.55511 7.99995L6.14491 9.83669C6.95307 10.3142 8.4938 10.7709 9.83675 9.83669C10.3981 9.4462 10.9198 9.09175 11.3555 8.79567C11.9036 8.42326 12.3158 8.14321 12.5 7.99995H3.55511Z"
      fill="#242021"
    />
    <path
      d="M9.83674 8.00007C9.83674 9.01447 9.0144 9.8368 8 9.8368C6.9856 9.8368 6.16327 9.01447 6.16327 8.00007C6.16327 6.98566 6.9856 6.16333 8 6.16333C9.0144 6.16333 9.83674 6.98566 9.83674 8.00007Z"
      fill="#EFDEC0"
    />
    <path
      d="M9.02858 8.00001C9.02858 8.56807 8.56807 9.02858 8.00001 9.02858C7.43194 9.02858 6.97144 8.56807 6.97144 8.00001C6.97144 7.43194 7.43194 6.97144 8.00001 6.97144C8.56807 6.97144 9.02858 7.43194 9.02858 8.00001Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 14.0612C11.3475 14.0612 14.0612 11.3475 14.0612 8.00002C14.0612 4.65249 11.3475 1.93879 8.00002 1.93879C4.65249 1.93879 1.93879 4.65249 1.93879 8.00002C1.93879 11.3475 4.65249 14.0612 8.00002 14.0612ZM8.00002 14.2816C11.4693 14.2816 14.2816 11.4693 14.2816 8.00002C14.2816 4.53077 11.4693 1.71838 8.00002 1.71838C4.53077 1.71838 1.71838 4.53077 1.71838 8.00002C1.71838 11.4693 4.53077 14.2816 8.00002 14.2816Z"
      fill="#242021"
    />
  </svg>
);
