import * as React from "react";
import type { SVGProps } from "react";
export const SvgMiscDownloadFill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="#fff"
      d="M5.833 17.484a5.417 5.417 0 0 1-2.446-10 6.668 6.668 0 0 1 13.226 0 5.417 5.417 0 0 1-2.446 10v.016H5.833v-.016Zm5-7.484V6.667H9.167V10h-2.5L10 14.167 13.333 10h-2.5Z"
    />
  </svg>
);
