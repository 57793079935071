import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolYoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M9.183 3c.4.002 1.403.012 2.468.055l.377.016c1.072.05 2.143.137 2.675.286.709.199 1.266.78 1.454 1.516.3 1.17.337 3.452.342 4.004v.245c-.005.552-.043 2.834-.342 4.004-.191.739-.748 1.32-1.454 1.516-.531.148-1.603.235-2.675.286l-.377.016A75.52 75.52 0 0 1 9.183 15h-.367c-.848-.005-4.393-.043-5.52-.357a2.108 2.108 0 0 1-1.454-1.516c-.3-1.17-.337-3.452-.342-4.004v-.245c.005-.552.042-2.835.342-4.004.19-.74.748-1.32 1.454-1.516 1.127-.314 4.672-.352 5.52-.357h.367ZM7.499 6.374v5.25L12 9 7.5 6.374Z"
    />
  </svg>
);
