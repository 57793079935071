import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M16.66 4.242a6.29 6.29 0 0 1-1.802.494A3.147 3.147 0 0 0 16.237 3a6.26 6.26 0 0 1-1.992.761A3.137 3.137 0 0 0 8.9 6.621a8.906 8.906 0 0 1-6.465-3.276 3.136 3.136 0 0 0 .971 4.188 3.127 3.127 0 0 1-1.42-.393l-.001.039A3.139 3.139 0 0 0 4.5 10.255a3.155 3.155 0 0 1-1.416.054 3.139 3.139 0 0 0 2.93 2.178 6.296 6.296 0 0 1-4.644 1.299 8.874 8.874 0 0 0 4.808 1.41c5.77 0 8.925-4.78 8.925-8.925 0-.136-.004-.272-.01-.406a6.373 6.373 0 0 0 1.565-1.623Z"
    />
  </svg>
);
