import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolUggLogoBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 143 46" {...props}>
    <path fill="#3273fa" d="M45.924 44.96a3.476 3.476 0 1 1 2.464 1.043 3.345 3.345 0 0 1-2.464-1.043z" />
    <path
      fill="#fff"
      d="M97.347 16.759v-.192c0-7.838-4.01-11.757-12.03-11.757H74.61c-8.02 0-12.03 3.92-12.03 11.757v17.664c0 7.843 4.01 11.765 12.03 11.765h10.683c8.02 0 12.03-3.922 12.03-11.765v-9.655H81.26l-2.791 6.114h12.03v3.54c0 3.816-1.721 5.721-5.165 5.716H74.61c-3.448 0-5.173-1.905-5.173-5.715V16.575c0-3.804 1.725-5.707 5.173-5.707h10.683c3.443 0 5.165 1.903 5.165 5.707v3.197zM142.277 16.759v-.192c0-7.838-4.009-11.757-12.027-11.757h-10.713c-8.018 0-12.027 3.92-12.027 11.757v17.664c0 7.843 4.01 11.765 12.027 11.765h10.68c8.02 0 12.028-3.922 12.028-11.765v-9.655h-16.052l-2.79 6.114h12.027v3.54c0 3.816-1.724 5.721-5.172 5.716h-10.72c-3.443 0-5.164-1.905-5.164-5.715V16.575c0-3.804 1.721-5.707 5.163-5.707h10.68c3.449 0 5.173 1.903 5.173 5.707v3.197zM27.181 4.28v31.971a20.366 20.366 0 0 1-10.04 2.716A20.334 20.334 0 0 1 7.066 36.25V4.28L0 7.376v32.718l1.599 1.072A28.116 28.116 0 0 0 17.124 46a28.116 28.116 0 0 0 15.51-4.835l1.599-1.04V7.376z"
    />
    <path fill="#3273fa" d="M16.827 0 13.37 1.6v23.036c1.129.332 2.298.502 3.473.504a12.418 12.418 0 0 0 3.48-.504V1.6z" />
  </svg>
);
