import * as React from "react";
import type { SVGProps } from "react";

export const SvgGameDeadlockGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 16">
    <g>
      <g id="Layer_1">
        <path d="M15.2,8c0,4-3.2,7.2-7.2,7.2S.8,12,.8,8,4,.8,8,.8s7.2,3.2,7.2,7.2Z" fill="#5c5c8e" />
        <path d="M15.2,8.1H.8v-.2h14.4v.2Z" fill="#242021" fillRule="evenodd" />
        <path d="M8.1.8v14.4h-.2V.8h.2Z" fill="#242021" fillRule="evenodd" />
        <path d="M3.7,3.6l8.7,8.7-.2.2L3.6,3.7l.2-.2Z" fill="#242021" fillRule="evenodd" />
        <path d="M12.4,3.7L3.7,12.4l-.2-.2L12.3,3.6l.2.2Z" fill="#242021" fillRule="evenodd" />
        <path
          d="M3.6,8l2.6-1.8c.8-.5,2.3-.9,3.7,0,.6.4,1.1.7,1.5,1,.5.4,1,.7,1.1.8H3.6ZM3.6,8l2.6,1.8c.8.5,2.3.9,3.7,0,.6-.4,1.1-.7,1.5-1,.5-.4,1-.7,1.1-.8H3.6Z"
          fill="#242021"
          fillRule="evenodd"
        />
        <path d="M9.8,8c0,1-.8,1.8-1.8,1.8s-1.8-.8-1.8-1.8.8-1.8,1.8-1.8,1.8.8,1.8,1.8Z" fill="#5c5c8e" />
        <path d="M9,8c0,.6-.5,1-1,1s-1-.5-1-1,.5-1,1-1,1,.5,1,1Z" fill="#231f20" />
        <path
          d="M8,14.1c3.3,0,6.1-2.7,6.1-6.1S11.3,1.9,8,1.9,1.9,4.7,1.9,8s2.7,6.1,6.1,6.1ZM8,14.3c3.5,0,6.3-2.8,6.3-6.3S11.5,1.7,8,1.7,1.7,4.5,1.7,8s2.8,6.3,6.3,6.3Z"
          fill="#242021"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
