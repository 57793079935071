import * as React from "react";
import type { SVGProps } from "react";
export const SvgLolInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="#5C5C8E"
      d="M9 6.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm0-1.5a3.75 3.75 0 1 1 .001 7.5 3.75 3.75 0 0 1 0-7.5Zm4.876-.188a.938.938 0 1 1-1.877-.001.938.938 0 0 1 1.877 0ZM9 3c-1.856 0-2.159.005-3.022.043-.588.028-.982.107-1.348.25-.326.125-.56.276-.81.526a2.167 2.167 0 0 0-.528.811c-.143.367-.222.761-.249 1.348-.039.828-.043 1.118-.043 3.022 0 1.856.005 2.158.043 3.021.028.588.107.983.249 1.348.126.326.277.562.526.81.252.253.487.403.81.528.37.143.765.223 1.35.25.828.038 1.118.043 3.022.043 1.856 0 2.158-.005 3.021-.043.587-.028.982-.107 1.348-.25.325-.125.561-.277.81-.526.253-.252.403-.486.528-.81.143-.37.222-.764.25-1.35.038-.827.043-1.117.043-3.021 0-1.856-.005-2.158-.044-3.021-.027-.587-.107-.983-.248-1.35a2.184 2.184 0 0 0-.528-.81 2.163 2.163 0 0 0-.81-.527c-.367-.142-.762-.222-1.349-.249C11.194 3.005 10.905 3 9.001 3Zm0-1.5c2.037 0 2.292.008 3.092.045.798.037 1.342.163 1.82.349.495.19.912.448 1.329.865.416.417.674.835.865 1.329.185.477.311 1.022.349 1.82.035.8.045 1.054.045 3.092 0 2.037-.008 2.292-.045 3.092-.037.798-.164 1.342-.349 1.82-.19.495-.449.912-.865 1.33a3.685 3.685 0 0 1-1.329.864c-.478.185-1.022.311-1.82.349-.8.036-1.055.045-3.092.045-2.038 0-2.292-.008-3.092-.045-.798-.037-1.342-.164-1.82-.349a3.668 3.668 0 0 1-1.33-.865 3.68 3.68 0 0 1-.865-1.329c-.185-.478-.31-1.022-.348-1.82C1.51 11.292 1.5 11.037 1.5 9s.007-2.292.045-3.092c.037-.799.163-1.342.348-1.82a3.66 3.66 0 0 1 .865-1.33 3.673 3.673 0 0 1 1.33-.864c.477-.186 1.021-.311 1.82-.349C6.709 1.509 6.963 1.5 9 1.5Z"
    />
  </svg>
);
